/**
 * component for internal testing of various raycaster features
 */
AFRAME.registerComponent("raycaster-listen", {
    init: function () {
        // Use events to figure out what raycaster is listening so we don't have to
        // hardcode the raycaster.

        this.logPrefix = `[ray '${this.el.id}']`;

        console.log(`${this.logPrefix} init`);

        this.el.addEventListener("raycaster-intersected", (evt) => {
            this.raycaster = evt.detail.el;
            console.log(`${this.logPrefix} raycaster-intersected`);
        });
        this.el.addEventListener("raycaster-intersected-cleared", (evt) => {
            this.raycaster = null;
            console.log(`${this.logPrefix} raycaster-intersected-cleared`);
        });

        this.el.addEventListener("raycaster-intersection", (evt) => {
            console.log(`${this.logPrefix} raycaster-intersection`);
        });
        this.el.addEventListener("raycaster-intersection-cleared", (evt) => {
            console.log(`${this.logPrefix} raycaster-intersection-cleared`);
        });

        this.el.addEventListener("click", () => {
            if (!this.raycaster) {
                return;
            } // Not intersecting.

            let intersection = this.raycaster.components.raycaster.getIntersection(this.el);
            if (!intersection) {
                return;
            }
            // console.log(intersection.point);

            var npcEl = document.querySelector("#npc");
            npcEl.setAttribute("nav-agent", {
                active: true,
                destination: intersection.point,
            });
        });
    },

    tick: function () {
        return;
        if (!this.raycaster) {
            return;
        } // Not intersecting.

        let intersection = this.raycaster.components.raycaster.getIntersection(this.el);
        if (!intersection) {
            return;
        }
        // console.log(intersection.point);

        var npcEl = document.querySelector("#npc");
        npcEl.setAttribute("nav-agent", {
            active: true,
            destination: intersection.point,
        });
    },
});
